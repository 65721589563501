/* // @import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap';
// @import 'https://fonts.googleapis.com/icon?family=Material+Icons'; */

body{
    background-color: #f4f5f7!important;
}

.dzu-dropzone{
    overflow: hidden !important;
}

.dzu-inputLabelWithFiles{
    background-color: transparent !important;
    /* margin-left: 0 !important;
    margin-top: 3px !important;
    display: none !important; */
}
.formElementWrapper{position: relative}
.MuiFormHelperText-contained.Mui-error{
    font-size: 12px;
    position: absolute;
    left: 0;
    margin: 0;
    bottom: -16px;
    color: #f44336;
}
.disabledLabel,.disabledLabel .MuiFormLabel-asterisk{
    color: #bfbfbf !important;
}

/*disabled field styling*/
.Mui-disabled{
    background-color: #f4f5f7;
    color: rgba(0,0,0,0.38);
}
.Mui-disabled fieldset{
    border-color: transparent!important;
}
/*disabled field styling end*/

/* fields border color */
.MuiOutlinedInput-notchedOutline, textarea{
    border-color: #a0a5b9!important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
    border-color: #3a3a3a!important;
}


.Mui-disabled:hover .MuiOutlinedInput-notchedOutline{
    border-color: transparent!important;
}

.PrivateNotchedOutline-root-1712.MuiOutlinedInput-notchedOutline, textarea{
    border-color: #3f74e8!important; /*secondary*/
}
.MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #3f74e8!important; /*secondary*/
}

/* .MuiButton-outlined:not(.MuiButton-outlinedSecondary), .MuiButton-outlined:not(.MuiButton-outlinedPrimary) {
    border-color: #a0a5b9;
    color: #3a3a3a;
} */

/* breadcrumb issues */
.MuiBreadcrumbs-li:not(:first-child):hover a{
    text-decoration: none;
    cursor: default;
}

.btnSmall{
    min-width: 106px!important;
}
.btnMedium{
    min-width: 136px!important;
}